import Dialog from "@mui/material/Dialog";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, InputLabel, Typography } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import DialogTextFieldRef from "components/Edit/Dialog/DialogTextFieldRef";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";
import TextField from "@mui/material/TextField";
import moment from "moment/moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormHelperText from "@mui/material/FormHelperText";
import { MuiColorInput } from "mui-color-input";

const templateTypes = [
  { id: "PROMOTION", displayName: "Kod rabatowy" },
  { id: "CLASSIC", displayName: "Klasyczny" },
];

const AddSliderDialog = ({ open, handleClose, handleRemove, handleConfirm, itemToEdit }) => {
  moment.locale("pl");
  const { t } = useTranslation("common");

  const [colorText, setColorText] = useState();
  const [colorButton, setColorButton] = useState();
  const [colorButtonText, setColorButtonText] = useState();

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  const [template, setTemplate] = useState();

  const [name, setName] = useState("");
  const refName = useRef(null);
  const [content, setContent] = useState("");
  const refContent = useRef(null);
  const [buttonContent, setButtonContent] = useState("");
  const refButtonContent = useRef(null);
  const [url, setURL] = useState("");
  const refUrl = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundImageAltText, setBackgroundImageAltText] = useState("");
  const refBackgroundImageAltText = useRef(null);

  const [backgroundMobileImage, setBackgroundMobileImage] = useState("");

  const [promotionCode, setPromotionCode] = useState("");
  const refPromotionCode = useRef(null);
  const [promotionPriceFrom, setPromotionPriceFrom] = useState("");
  const refPromotionPriceFrom = useRef(null);

  const [promotionValidFor, setPromotionValidFor] = useState();

  useEffect(() => {
    if (itemToEdit) {
      refName.current.value = itemToEdit.name;
      refContent.current.value = itemToEdit.content;
      refButtonContent.current.value = itemToEdit.buttonContent;
      refUrl.current.value = itemToEdit.url;
      refBackgroundImageAltText.current.value = itemToEdit.backgroundImageAltText;
      refPromotionCode.current.value = itemToEdit.promotionCode;
      refPromotionPriceFrom.current.value = itemToEdit.promotionPriceFrom;

      setTemplate(itemToEdit.template);
      setName(itemToEdit.name);
      setContent(itemToEdit.content);
      setButtonContent(itemToEdit.buttonContent);
      setURL(itemToEdit.url);
      setBackgroundImage(itemToEdit.backgroundImage);
      setBackgroundImageAltText(itemToEdit.backgroundImageAltText);
      setBackgroundMobileImage(itemToEdit.backgroundMobileImage);
      setPromotionCode(itemToEdit.promotionCode);
      setPromotionPriceFrom(itemToEdit.promotionPriceFrom);
      setPromotionValidFor(itemToEdit.promotionValidFor);

      setColorText(itemToEdit.colorText);
      setColorButton(itemToEdit.colorButton);
      setColorButtonText(itemToEdit.colorButtonText);
    } else {
      resetValue();
    }
  }, [itemToEdit, open]);

  const handleChangeDate = (value) => {
    if (value) {
      setPromotionValidFor(value.format("yyyy-MM-DD"));
    } else {
      setPromotionValidFor("");
    }
  };

  const checkErrors = useCallback(() => {
    let isErrors = false;
    let errorsTmp = {};

    if (template === "PROMOTION" && (name === null || name === undefined || name === "")) {
      errorsTmp = { name: { message: t("form-validation-required") } };
      isErrors = true;
    }

    if (backgroundImage === null || backgroundImage === undefined || backgroundImage === "") {
      errorsTmp = { ...errorsTmp, backgroundImage: { message: t("form-validation-required") } };
      isErrors = true;
    }

    if (template === "PROMOTION" && (promotionCode === null || promotionCode === undefined || promotionCode === "")) {
      errorsTmp = { ...errorsTmp, promotionCode: { message: t("form-validation-required") } };
      isErrors = true;
    }

    if (template === "PROMOTION" && promotionPriceFrom !== "" && promotionPriceFrom !== null) {
      if (isNaN(promotionPriceFrom) || promotionPriceFrom <= 0) {
        errorsTmp = { ...errorsTmp, promotionPriceFrom: { message: t("form-validation-number") } };
        isErrors = true;
      }
    }

    setErrors(errorsTmp);
    return isErrors;
  }, [name, backgroundImage, promotionCode, promotionPriceFrom, template]);

  useEffect(() => {
    if (isDirty) {
      checkErrors();
    }
  }, [checkErrors]);

  const handleSubmit = () => {
    let isError = false;
    setErrors({});
    setIsDirty(true);

    isError = checkErrors();

    if (!isError) {
      setErrors({});

      let slider = {
        name: name,
        content: content,
        buttonContent: buttonContent,
        url: url,
        backgroundImage: backgroundImage,
        backgroundImageAltText: backgroundImageAltText,
        backgroundMobileImage: backgroundMobileImage,
        template: template,
        promotionValidFor: promotionValidFor,
        promotionCode: promotionCode,
        promotionPriceFrom: promotionPriceFrom,
        colorText: colorText,
        colorButton: colorButton,
        colorButtonText: colorButtonText,
      };

      console.log(promotionPriceFrom);

      if (itemToEdit) {
        slider = { ...slider, id: itemToEdit.id, sortOrder: itemToEdit.sortOrder };
      }

      resetValue();

      handleConfirm(slider);
    }
  };

  const resetValue = () => {
    setIsDirty(false);
    setErrors({});
    refName.current.value = "";
    refContent.current.value = "";
    refButtonContent.current.value = "";
    refUrl.current.value = "";
    refBackgroundImageAltText.current.value = "";
    refPromotionPriceFrom.current.value = "";
    refPromotionCode.current.value = "";

    setName("");
    setContent("");
    setButtonContent("");
    setURL("");
    setBackgroundImageAltText("");
    setBackgroundImage("");
    setBackgroundMobileImage("");
    setTemplate("PROMOTION");
    setPromotionValidFor(null);
    setPromotionPriceFrom("");
    setPromotionCode("");

    setColorText("#ffffff");
    setColorButton("#FC9527");
    setColorButtonText("#ffffff");
  };

  const handleDeleteItem = () => {
    if (itemToEdit) {
      handleRemove(itemToEdit);
    }

    resetValue();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={() => {
          resetValue();
          handleClose();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              {itemToEdit ? "Edytuj slajd" : "Dodaj slajd"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor={"template"}>Szablon</InputLabel>
            <TextField
              SelectProps={{
                native: true,
              }}
              placeholder="Wybierz szablon"
              select
              id="template"
              helperText={errors["template"] ? errors["template"].message : ""}
              error={!!errors["template"]}
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={(e) => {
                setTemplate(e.target.value);
              }}
              value={template ? template : ""}
            >
              {templateTypes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.displayName}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <DialogTextFieldRef
              label={"Nagłówek"}
              name={"name"}
              errors={errors}
              setValue={setName}
              refItem={refName}
              placeholder={template === "PROMOTION" ? "Badanie XYZ 100zł taniej z kodem:" : ""}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Kolor tekstu</InputLabel>
            <MuiColorInput
              format="hex"
              fullWidth
              size="small"
              isAlphaHidden
              value={colorText}
              onChange={(e) => {
                setColorText(e);
              }}
            />
          </Grid>
          <Grid item xs={6} sx={template === "CLASSIC" ? {} : { display: "none" }}>
            <DialogTextFieldRef
              label={"Treść"}
              name={"content"}
              errors={errors}
              setValue={setContent}
              refItem={refContent}
            />
          </Grid>
          <Grid item xs={6}>
            <DialogTextFieldRef
              label={"Tekst na przycisku"}
              name={"buttonLabel"}
              errors={errors}
              setValue={setButtonContent}
              refItem={refButtonContent}
            />
          </Grid>
          <Grid item xs={6}>
            <DialogTextFieldRef
              label={"URL przycisku"}
              name={"url"}
              errors={errors}
              setValue={setURL}
              refItem={refUrl}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Kolor tekstu przycisku</InputLabel>
            <MuiColorInput
              format="hex"
              fullWidth
              size="small"
              isAlphaHidden
              value={colorButtonText}
              onChange={(e) => {
                setColorButtonText(e);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Kolor przycisku</InputLabel>
            <MuiColorInput
              format="hex"
              fullWidth
              size="small"
              isAlphaHidden
              value={colorButton}
              onChange={(e) => {
                setColorButton(e);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomizedImagePicker
              label={"Obrazek tła desktop"}
              placeholder={"Wybrany obrazek:"}
              image={backgroundImage}
              setImage={setBackgroundImage}
            />
            <FormHelperText error sx={{ mt: "-15px" }}>
              {errors["backgroundImage"]?.message}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <CustomizedImagePicker
              label={"Obrazek tła mobile"}
              placeholder={"Wybrany obrazek:"}
              image={backgroundMobileImage}
              setImage={setBackgroundMobileImage}
            />
          </Grid>
          <Grid item xs={12}>
            <DialogTextFieldRef
              label={"Tekst alternatywny obrazka"}
              name={"altBackground"}
              errors={errors}
              setValue={setBackgroundImageAltText}
              refItem={refBackgroundImageAltText}
            />
          </Grid>
          <Grid item xs={12} sx={template === "PROMOTION" ? {} : { display: "none" }}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <DialogTextFieldRef
                  label={"Kod rabatowy"}
                  name={"promotionCode"}
                  errors={errors}
                  setValue={setPromotionCode}
                  refItem={refPromotionCode}
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <InputLabel>Kod ważny do</InputLabel>
                  <DatePicker
                    inputFormat={"yyyy-MM-DD"}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        size="small"
                        helperText={errors["promotionValidFor"] ? errors["promotionValidFor"].message : ""}
                        error={!!errors["promotionValidFor"]}
                      />
                    )}
                    value={promotionValidFor ? moment.utc(promotionValidFor).format("yyyy-MM-DD") : ""}
                    onChange={(v) => handleChangeDate(v)}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <DialogTextFieldRef
                  type="number"
                  label={"Cena od"}
                  name={"promotionPriceFrom"}
                  errors={errors}
                  setValue={setPromotionPriceFrom}
                  refItem={refPromotionPriceFrom}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={6}>
            <Button variant="outlined" color="default" sx={{ color: color.red }} onClick={handleDeleteItem}>
              Usuń
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Zapisz
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default AddSliderDialog;
