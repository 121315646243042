import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  List,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import CustomizedListItem from "components/Edit/CustomizedListItem";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";
import Wysiwyg from "components/Edit/Wysiwyg";
import CategoryEditListDialog from "modules/CMSDocuments/CategoryEditListDialog";
import CategoryEditListDialogControled from "modules/CMSDocuments/CategoryEditListDialogControled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TabPanel from "components/Edit/CustomizedTabPanel";
import Box from "@mui/material/Box";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import ContentsTabsPanel from "components/Edit/Entry/ContentsTabsPanel";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import CustomizedDatePickerMui from "components/Edit/CustomizedDateTimePickerMui";
import moment from "moment/moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RowDnDProductInCategory } from "components/Edit/DnD/RowDnDProductInCategory";
import CustomizedMetaTextField from "components/Edit/CustomizedMetaTextField";

const endpoint = "/article/";
const title = "cms_post_edit_label";
const moduleUrl = "/cms/posts/";

const MAX_SHORT_LIST = 5;

const EntryEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    articleTitle: Yup.string().trim().required(t("form-validation-required")),
    url: Yup.string().trim().required(t("form-validation-required")),
    dateOfPublish: Yup.string().trim().required(t("form-validation-required")),
    dateOfUpdate: Yup.string().nullable(),
    status: Yup.bool().required(t("form-validation-required")),
    excludeFromSiteMap: Yup.bool().nullable(),
    image: Yup.string().trim().nullable(),
    alt: Yup.string().trim().nullable(),
    intro: Yup.string().trim().nullable(),
    metaTitle: Yup.string().trim().required(t("form-validation-required")),
    metaDescription: Yup.string().trim().required(t("form-validation-required")),
    articleTitleTopTop: Yup.string().trim().nullable(),
    articleContentTopTop: Yup.string().trim().nullable(),
    articleTitleTopBottom: Yup.string().trim().nullable(),
    articleContentTopBottom: Yup.string().trim().nullable(),
    products: Yup.array(),
    categoryList: Yup.array(),
    tabs: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        name: Yup.string().nullable(),
        content: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
  });

  const defaultValue = {
    articleTitle: "",
    url: "",
    dateOfPublish: moment().format("yyyy-MM-DDTHH:mm"),
    dateOfUpdate: moment().format("yyyy-MM-DDTHH:mm"),
    status: true,
    excludeFromSiteMap: false,
    image: "",
    alt: "",
    intro: "",
    metaTitle: "",
    metaDescription: "",
    articleTitleTopTop: "",
    articleContentTopTop: "",
    articleTitleTopBottom: "",
    articleContentTopBottom: "",
    products: [],
    categoryList: [],
    tabs: [],
  };

  const refForm = useRef(null);
  const changeListRef = useRef(null);

  const handleEditList = (item) => {
    console.log(item);
  };

  return (
    <>
      <CategoryEditListDialog ref={changeListRef} confirm={handleEditList} />
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        preview={true}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Wpis"}
      >
        <FormData id={id} current={current} refForm={refForm} changeListRef={changeListRef} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm, changeListRef }) => {
  const { control, getValues, setValue } = useFormContext();
  const { t } = useTranslation("common");
  const [activeTabLinked, setActiveTabLinked] = useState(0);
  const [allActiveCategory, setAllActiveCategory] = useState([]);
  const [allActiveProducts, setAllActiveProducts] = useState([]);
  const [openedDialogChoiceCategory, setOpenedDialogChoiceCategory] = useState(false);
  const [openedDialogChoiceProduct, setOpenedDialogChoiceProduct] = useState(false);
  const categoryList = useWatch({
    control,
    name: `categoryList`,
  });
  const products = useWatch({
    control,
    name: `products`,
  });

  const [isMoreCategories, setIsMoreCategories] = useState(false);
  const [isMoreProducts, setIsMoreProducts] = useState(false);

  useEffect(() => {
    backend.get("/article-category/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveCategory(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveCategory([]);
    };
  }, []);
  useEffect(() => {
    backend.get("/product/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveProducts(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveProducts([]);
    };
  }, []);

  const handleChangeLinked = (event, newValue) => {
    setActiveTabLinked(newValue);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const fields = getValues("products");
    const myMove = (from, to, array) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return [...array];
    };

    const newFields = myMove(dragIndex, hoverIndex, fields);
    setValue("products", newFields);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Tytuł"} name={"articleTitle"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"URL"} name={"url"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDatePickerMui label={"Data publikacji"} name="dateOfPublish" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedDatePickerMui label={"Data aktualizacji"} name="dateOfUpdate" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <CustomizedSwitch
                  name={"status"}
                  topLabel={t("cms_edit_page_state")}
                  rightLabel={t("cms_edit_page_online")}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedSwitch name={"excludeFromSiteMap"} topLabel={"Usuń z sitemapy"} rightLabel={"Tak"} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomizedImagePicker
                  nameUrl={"image"}
                  nameAlt={"alt"}
                  label={"Obrazek wyróżniający"}
                  placeholder={"Wybrany obrazek wyróżniający:"}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Tekst alternatywny obrazka"} name={"alt"} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Wysiwyg label={"Zajawka"} name={"intro"} />
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  {t("cms_edit_seo_data")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <CustomizedMetaTextField label={"Meta title"} name={"metaTitle"} type="TITLE" />
                <br />
                <CustomizedMetaTextField label={"Meta description"} name={"metaDescription"} type="DESCRIPTION" />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
      <Tabs onChange={handleChangeLinked} value={activeTabLinked} aria-label="tabSection" centered>
        <Tab label="Top box content" />
        <Tab label="Produkty powiązane" />
        <Tab label="Kategorie powiązane" />
      </Tabs>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <TabPanel value={activeTabLinked} index={0}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <CustomizedTextField label={"Tytuł góra"} name={"articleTitleTopTop"} />
            </Grid>
            <Grid item xs={12}>
              <Wysiwyg label={"Treść góra"} name={"articleContentTopTop"} />
            </Grid>
            <Grid item xs={12}>
              <CustomizedTextField label={"Tytuł dół"} name={"articleTitleTopBottom"} />
            </Grid>
            <Grid item xs={12}>
              <Wysiwyg label={"Treść dół"} name={"articleContentTopBottom"} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTabLinked} index={1}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setOpenedDialogChoiceProduct(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!products?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              {/*<List dense sx={{pb: 0}}>*/}
              {/*    {products?.map((item, index) =>*/}
              {/*        ((index < MAX_SHORT_LIST || isMoreProducts) && <CustomizedListItem*/}
              {/*            key={item.id}*/}
              {/*            primaryText={item.name}*/}
              {/*            secondaryText={item.url}*/}
              {/*        />)*/}
              {/*    )}*/}
              {/*</List>*/}
              <Table sx={{ pb: 0 }}>
                <TableBody>
                  <DndProvider backend={HTML5Backend}>
                    {products?.map(
                      (item, index) =>
                        (index < MAX_SHORT_LIST || isMoreProducts) && (
                          <RowDnDProductInCategory
                            key={item.id}
                            id={item.id}
                            handleMoveItem={moveItem}
                            index={index}
                            primaryText={item.name}
                            secondaryText={item.url}
                          />
                        ),
                    )}
                  </DndProvider>
                </TableBody>
              </Table>
              <MoreLessBoxIcon
                list={products}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreProducts}
                setFullListOpen={setIsMoreProducts}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={activeTabLinked} index={2}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setOpenedDialogChoiceCategory(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!categoryList?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {categoryList?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMoreCategories) && (
                      <CustomizedListItem key={item.id} primaryText={item.name} secondaryText={item.url} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={categoryList}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreCategories}
                setFullListOpen={setIsMoreCategories}
              />
            </>
          )}
        </TabPanel>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <ContentsTabsPanel />
      </Paper>
      <CategoryEditListDialogControled
        handleClose={() => setOpenedDialogChoiceCategory(false)}
        open={openedDialogChoiceCategory}
        allItemsTable={Array.isArray(allActiveCategory) ? allActiveCategory : []}
        nameFormTables={"categoryList"}
        nameToDisplay={"name"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setOpenedDialogChoiceProduct(false)}
        open={openedDialogChoiceProduct}
        allItemsTable={Array.isArray(allActiveProducts) ? allActiveProducts : []}
        nameFormTables={"products"}
        nameToDisplay={"name"}
      />
    </>
  );
};

export default EntryEdit;
