import React from "react";
import TableCell from "@mui/material/TableCell";
import RemoveIcon from "@mui/icons-material/Remove";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

const CellProductCategoryNameLevel = ({ data, row, handleClick }) => {
  return (
    <TableCell onClick={(e) => handleClick(e, row)}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        {[...Array(data.level)].map((_, index) => (
          <RemoveIcon key={index} fontSize="small" />
        ))}
        <Typography>{data?.name}</Typography>
      </Stack>
    </TableCell>
  );
};

export default CellProductCategoryNameLevel;
