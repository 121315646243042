import React, { useEffect, useState } from "react";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useFormContext } from "react-hook-form";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";

const SelectDiscountCodeBanner = () => {
  const { getValues, watch, setValue } = useFormContext();
  const [allActiveDiscountCodes, setAllActiveDiscountCodes] = useState([]);
  const [discountCodeItems, setDiscountCodeItems] = useState([]);

  const discountCode = watch("promotionDiscountCodeId");

  useEffect(() => {
    if (allActiveDiscountCodes.length > 0) {
      const selected = allActiveDiscountCodes.find((item) => item.id === discountCode);
      setValue("promotionDiscountCodeItemId", "");

      if (selected) {
        setDiscountCodeItems(selected.codes);
      } else {
        setDiscountCodeItems([]);
      }
    }
  }, [discountCode]);

  useEffect(() => {
    backend.get("/discount-code/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const allDiscountCodes = res.data;
          const allActiveDiscountCodes = allDiscountCodes.filter((item) => {
            return item.active;
          });
          const dCodes = getValues("promotionDiscountCodeId");

          const foundElementInActive = allActiveDiscountCodes.find(function (element) {
            return element.id === dCodes;
          });

          const foundElementInAll = allDiscountCodes.find(function (element) {
            return element.id === dCodes;
          });

          if (!dCodes || foundElementInActive) {
            setAllActiveDiscountCodes(allActiveDiscountCodes);
            setDiscountCodeItems(foundElementInActive ? foundElementInActive.codes : []);
          } else if (foundElementInAll) {
            setAllActiveDiscountCodes([...allActiveDiscountCodes, foundElementInAll]);
            setDiscountCodeItems(foundElementInAll.codes);
          } else {
            setAllActiveDiscountCodes([
              ...allActiveDiscountCodes,
              { id: dCodes, name: "Item removed?", active: false },
            ]);
          }
        }
      }
    });
    return () => {
      setAllActiveDiscountCodes([]);
    };
  }, []);

  return (
    <>
      <Divider />
      <Grid item xs={12} sm={6}>
        <CustomizedSelect
          label={"Wyświetl informację o kodzie rabatowym"}
          name={"promotionDiscountCodeId"}
          items={allActiveDiscountCodes}
          labelField="name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomizedSelect
          disabled={discountCodeItems.length === 0}
          label={"Kod promocji"}
          name={"promotionDiscountCodeItemId"}
          items={discountCodeItems}
          labelField="code"
        />
      </Grid>
    </>
  );
};

export default SelectDiscountCodeBanner;
