import TableCell from "@mui/material/TableCell";
import { Checkbox } from "@mui/material";
import { HEAD_CELL_TYPES } from "enums/headCellTypes";
import CellLanguageActions from "components/Table/CellLanguageActions";
import CellStatus from "components/Table/CellStatus";
import TableRow from "@mui/material/TableRow";
import React from "react";
import CellProductCategoryNameLevel from "components/Table/CellProductCategoryNameLevel";

const DataTableProductCategoryRow = ({
  row,
  handleCheckOneCheckbox,
  checkedCheckboxIdRow,
  headCells,
  handleClickLanguageAction,
  languages,
  handleClick,
}) => {
  return (
    <TableRow hover role="checkbox" key={row}>
      <TableCell align="left">
        <Checkbox
          color={"default"}
          size={"small"}
          onClick={(e) => handleCheckOneCheckbox(e, row)}
          checked={checkedCheckboxIdRow.includes(row.id)}
        />
      </TableCell>

      {headCells.map((hc, i) => {
        if (hc.type === HEAD_CELL_TYPES.CATEGORY_LEVEL_NAME) {
          return <CellProductCategoryNameLevel key={i} data={row[hc.id]} row={row} handleClick={handleClick} />;
        }
        if (hc.type === HEAD_CELL_TYPES.LANGUAGE_ACTION) {
          return (
            <CellLanguageActions
              key={i}
              data={row[hc.id]}
              row={row}
              handleClick={handleClickLanguageAction}
              languages={languages}
            />
          );
        }
        if (hc.type === HEAD_CELL_TYPES.STATUS) {
          return <CellStatus key={i} data={row[hc.id]} type={hc.type} row={row} handleClick={handleClick} />;
        }
        return (
          <TableCell
            key={i}
            align={hc.numeric ? "right" : "left"}
            onClick={(e) => handleClick(e, row)}
            style={!hc.bool ? { whiteSpace: "normal", wordBreak: "break-word" } : {}}
          >
            {row[hc.id]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
export default DataTableProductCategoryRow;
