import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Chip, OutlinedInput } from "@mui/material";
import { getDisplayNameFromProductFamilyTypes, productFamilyTypes } from "modules/ShopCatalog/ProductObjs";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const ProductAttributesMultiSelect = () => {
  const theme = useTheme();
  const { watch, setValue } = useFormContext();

  const watchTypesProducts = watch("typesProducts");
  const typeProducts = watchTypesProducts || [];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const v = typeof value === "string" ? value.split(",") : value;
    setValue("typesProducts", v);
  };

  return (
    <>
      <InputLabel htmlFor={"product-family-types-label"}>Atrybut przeznaczony dla produktów typu:</InputLabel>
      <FormControl fullWidth>
        <Select
          labelId="product-family-types-label"
          id="demo-multiple-chip"
          size="small"
          fullWidth
          multiple
          value={typeProducts}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={getDisplayNameFromProductFamilyTypes(value)} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {productFamilyTypes.map((item) => (
            <MenuItem key={item.id} value={item.id} style={getStyles(item.id, typeProducts, theme)}>
              {item.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default ProductAttributesMultiSelect;
