import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  List,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import CustomizedListItem from "components/Edit/CustomizedListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TabPanel from "components/Edit/CustomizedTabPanel";
import Box from "@mui/material/Box";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import CategoryEditListDialogControled from "modules/CMSDocuments/CategoryEditListDialogControled";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RowDnDProductInCategory } from "components/Edit/DnD/RowDnDProductInCategory";
import CustomizedMetaTextField from "components/Edit/CustomizedMetaTextField";

const endpoint = "/article-category/";
const title = "cms_category_edit_label";
const moduleUrl = "/cms/categories/";

const MAX_SHORT_LIST = 5;

const CategoryEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    image: Yup.string().trim().nullable(),
    url: Yup.string().trim().required(t("form-validation-required")),
    status: Yup.bool().required(t("form-validation-required")),
    excludeFromSiteMap: Yup.bool().nullable(),
    metaTitle: Yup.string().trim().nullable().required(t("form-validation-required")),
    metaDescription: Yup.string().trim().nullable().required(t("form-validation-required")),
    banners: Yup.array().nullable(),
  });

  const defaultValue = {
    name: "",
    url: "",
    image: "",
    status: true,
    excludeFromSiteMap: false,
    metaTitle: "",
    metaDescription: "",
    mostReadArticles: [],
    pinnedArticles: [],
    articleList: [],
    banners: [],
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        preview={true}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Kategorię"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { control, setValue, getValues } = useFormContext();

  const { t } = useTranslation("common");
  const [activeTabLinked, setActiveTabLinked] = useState(0);
  const [allActiveArticles, setAllActiveArticles] = useState([]);
  const [openedDialogChoiceArtice, setOpenedDialogChoiceArticle] = useState(false);
  const [openedDialogMostReadArticle, setOpenedDialogMostReadArticle] = useState(false);
  const [openedDialogChoicePinnedArticles, setOpenedDialogChoicePinnedArticles] = useState(false);

  const [isMorePinedArticle, setIsMorePinedArticle] = useState(false);
  const [isMoreOfenReadArticle, setIsMoreOfenReadArticle] = useState(false);
  const [isMoreArticleInCategory, setIsMoreArticleInCategory] = useState(false);

  const [isOpenDialogSelectBanners, setIsOpenDialogSelectBanners] = useState(false);
  const [isMoreBanners, setIsMoreBanners] = useState(false);
  const [allActiveBanners, setAllActiveBanners] = useState(null);

  const articleList = useWatch({
    control,
    name: `articleList`,
  });

  const pinnedArticles = useWatch({
    control,
    name: `pinnedArticles`,
  });

  const mostReadArticles = useWatch({
    control,
    name: `mostReadArticles`,
  });

  const imageWatch = useWatch({ control, name: "image" });

  const watchBanners = useWatch({
    control,
    name: `banners`,
  });

  useEffect(() => {
    backend.get("/article/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveArticles(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveArticles([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/banner/getAllPageBanner").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveBanners(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveBanners([]);
    };
  }, []);

  const handleChangeLinked = (event, newValue) => {
    setActiveTabLinked(newValue);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const fields = getValues("banners");
    const myMove = (from, to, array) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return [...array];
    };

    const newFields = myMove(dragIndex, hoverIndex, fields);
    setValue("banners", newFields);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"URL"} name={"url"} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomizedSwitch
              name={"status"}
              topLabel={t("cms_edit_page_state")}
              rightLabel={t("cms_edit_page_online")}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomizedSwitch name={"excludeFromSiteMap"} topLabel="Usuń z sitemapy" rightLabel={"Tak"} />
          </Grid>
          <Grid item xs={12}>
            <CustomizedImagePicker
              image={imageWatch}
              setImage={(image) => setValue("image", image)}
              label={"Obrazek kategorii"}
              placeholder={"Wybrany obrazek:"}
            />
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  {t("cms_edit_seo_data")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <CustomizedMetaTextField label={"Meta title"} name={"metaTitle"} type="TITLE" />
                <br />
                <CustomizedMetaTextField label={"Meta description"} name={"metaDescription"} type="DESCRIPTION" />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
      <Tabs onChange={handleChangeLinked} value={activeTabLinked} aria-label="tabSection" centered>
        <Tab label="Przypięte artykuły" />
        <Tab label="Najczęściej czytane" />
        <Tab label="Artykuły w kategorii" />
      </Tabs>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <TabPanel value={activeTabLinked} index={0}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setOpenedDialogChoicePinnedArticles(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!pinnedArticles?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {pinnedArticles?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMorePinedArticle) && (
                      <CustomizedListItem key={item.id} primaryText={item.title} secondaryText={item.url} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={pinnedArticles}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMorePinedArticle}
                setFullListOpen={setIsMorePinedArticle}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={activeTabLinked} index={1}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setOpenedDialogMostReadArticle(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!mostReadArticles?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {mostReadArticles?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMoreOfenReadArticle) && (
                      <CustomizedListItem key={item.id} primaryText={item.title} secondaryText={item.url} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={mostReadArticles}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreOfenReadArticle}
                setFullListOpen={setIsMoreOfenReadArticle}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={activeTabLinked} index={2}>
          <Box sx={{ textAlign: "right", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setOpenedDialogChoiceArticle(true)}
            >
              Edytuj listę
            </Button>
          </Box>
          {!articleList?.length > 0 ? (
            <Typography>Brak elementów</Typography>
          ) : (
            <>
              <List dense sx={{ pb: 0 }}>
                {articleList?.map(
                  (item, index) =>
                    (index < MAX_SHORT_LIST || isMoreArticleInCategory) && (
                      <CustomizedListItem key={item.id} primaryText={item.title} secondaryText={item.url} />
                    ),
                )}
              </List>
              <MoreLessBoxIcon
                list={articleList}
                maxShortListElement={MAX_SHORT_LIST}
                fullListOpen={isMoreArticleInCategory}
                setFullListOpen={setIsMoreArticleInCategory}
              />
            </>
          )}
        </TabPanel>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Bannery w kategorii
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Box sx={{ textAlign: "right", mb: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setIsOpenDialogSelectBanners(true)}
              >
                Edytuj listę
              </Button>
            </Box>
            {!watchBanners?.length > 0 ? (
              <Typography>Brak elementów</Typography>
            ) : (
              <>
                <Table sx={{ pb: 0 }}>
                  <TableBody>
                    <DndProvider backend={HTML5Backend}>
                      {watchBanners?.map(
                        (item, index) =>
                          (index < MAX_SHORT_LIST || isMoreBanners) && (
                            <RowDnDProductInCategory
                              key={item.id}
                              id={item.id}
                              handleMoveItem={(dragIndex, hoverIndex) => moveItem(dragIndex, hoverIndex, "banners")}
                              index={index}
                              primaryText={item.name}
                              secondaryText={item.buttonHref}
                            />
                          ),
                      )}
                    </DndProvider>
                  </TableBody>
                </Table>
                <MoreLessBoxIcon
                  list={watchBanners}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreBanners}
                  setFullListOpen={setIsMoreBanners}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
      {allActiveBanners && (
        <CategoryEditListDialogControled
          handleClose={() => setIsOpenDialogSelectBanners(false)}
          open={isOpenDialogSelectBanners}
          allItemsTable={allActiveBanners}
          nameFormTables={"banners"}
          nameToDisplay={"name"}
        />
      )}
      <CategoryEditListDialogControled
        handleClose={() => setOpenedDialogChoicePinnedArticles(false)}
        open={openedDialogChoicePinnedArticles}
        allItemsTable={Array.isArray(allActiveArticles) ? allActiveArticles : []}
        nameFormTables={"pinnedArticles"}
        nameToDisplay={"title"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setOpenedDialogMostReadArticle(false)}
        open={openedDialogMostReadArticle}
        allItemsTable={Array.isArray(allActiveArticles) ? allActiveArticles : []}
        nameFormTables={"mostReadArticles"}
        nameToDisplay={"title"}
      />
      <CategoryEditListDialogControled
        handleClose={() => setOpenedDialogChoiceArticle(false)}
        open={openedDialogChoiceArtice}
        allItemsTable={Array.isArray(allActiveArticles) ? allActiveArticles : []}
        nameFormTables={"articleList"}
        nameToDisplay={"title"}
      />
    </>
  );
};

export default CategoryEdit;
