import React, { memo, useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Grid, InputLabel } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

const CustomizedMetaTextField = ({ label, name, type = "TITLE" }) => {
  const { register, formState, getValues } = useFormContext();
  const [count, setCount] = useState(0);
  const { t } = useTranslation("common");

  const defaultHelperText =
    type === "TITLE"
      ? `${t("common_meta_title_size")} (${t("common_meta_current_size")}: ${count})`
      : type === "DESCRIPTION"
      ? `${t("common_meta_description_size")} (${t("common_meta_current_size")}: ${count})`
      : "";

  const updateCount = useCallback(
    debounce((count) => {
      setCount(count);
    }, 100),
    [],
  );

  useEffect(() => {
    const updateCount = (interval) => {
      const initialText = getValues(name);

      if (initialText !== undefined) {
        setCount(initialText.length);
        clearInterval(interval);
      }
    };
    const interval = setInterval(() => {
      updateCount(interval);
    }, 200);

    return () => clearInterval(interval);
  }, [name, getValues]);

  return (
    <>
      <Grid item xs={12}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <TextField
          {...register(name)}
          helperText={formState?.errors[name] ? formState?.errors[name].message : defaultHelperText}
          error={!!formState?.errors[name]}
          variant="outlined"
          size="small"
          fullWidth={true}
          onChange={(event) => {
            updateCount(event.target.value.length);
          }}
        />
      </Grid>
    </>
  );
};

export default memo(CustomizedMetaTextField);
