import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataTableHead } from "./DataTableHead";
import {
  clearAllFilters,
  fetchCrudList,
  selectListError,
  selectListFilters,
  selectListHeadCells,
  selectListInfo,
  selectListIsLoading,
  selectListItems,
  setListLimit,
  setListOrder,
  setListOrderBy,
  setListPage,
} from "redux/slices/crud";
import {
  selectListCloneDialogOpen,
  selectListDeleteDialogOpen,
  setCloneOrCopyButtonDisabled,
  setListCloneDialogOpen,
  setListDeleteDialogOpen,
} from "redux/slices/materials";
import {
  selectMenuItems,
  selectModulePath,
  setCurrentSelectedLevelOne,
  setCurrentSelectedLevelZero,
  setModulePath,
} from "redux/slices/menu";
import {
  changeLanguage,
  fetchAvailableLangs,
  selectLangCode,
  selectLanguages,
  setCurrentLanguageCode,
} from "redux/slices/language";

import Error from "components/Error";
import ConfirmationDialog from "components/ConfirmationDialog";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import RowNoData from "components/Table/RowNoData";
import { findMenu } from "utils/menu";
import DataTableProductCategoryRow from "components/Table/DataTableProductCategoryRow";

const useStyles = makeStyles((theme) => ({}));

const DataTableProductCategory = ({ modulePath, endpoint }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector(selectListFilters);
  const info = useSelector(selectListInfo);
  const headCells = useSelector(selectListHeadCells);
  const items = useSelector(selectListItems);
  const isLoading = useSelector(selectListIsLoading);
  const error = useSelector(selectListError);
  const { page, limit, order, orderBy } = filters;
  const { totalItems } = info;
  const { enqueueSnackbar } = useSnackbar();
  const savedModulePath = useSelector(selectModulePath);
  const currentLanguage = useSelector(selectLangCode);

  const [checkedCheckboxIdRow, setCheckedCheckboxIdRow] = useState([]);
  const [checkedCheckboxAll, setCheckedCheckboxAll] = useState(false);

  const deleteDialogOpen = useSelector(selectListDeleteDialogOpen);
  const cloneDialogOpen = useSelector(selectListCloneDialogOpen);

  const languages = useSelector(selectLanguages);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setListOrder(isAsc ? "desc" : "asc"));
    dispatch(setListOrderBy(property));
  };
  const handleChangePage = (event, newPage) => {
    dispatch(setListPage(newPage));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setListLimit(parseInt(event.target.value, 10)));
    dispatch(setListPage(0));
  };
  const handleClick = (e, row) => {
    changeLanguage("pl").then((r) => {
      if (r === true) {
        dispatch(setCurrentLanguageCode("pl"));
        history.push(modulePath + "/" + row.id + "/pl");
      }
    });
  };

  const handleClickLanguageAction = (e, row, langCode) => {
    if (currentLanguage != langCode) {
      changeLanguage(langCode).then((r) => {
        if (r === true) {
          dispatch(setCurrentLanguageCode(langCode));
          history.push(modulePath + "/" + row.id + "/" + langCode);
        }
      });
    } else {
      history.push(modulePath + "/" + row.id + "/" + langCode);
    }
  };

  useEffect(() => {
    dispatch(fetchAvailableLangs());
  }, []);

  const menuItems = useSelector(selectMenuItems);
  useEffect(() => {
    const { levelZero, levelOne } = findMenu(menuItems);
    dispatch(setCurrentSelectedLevelZero(levelZero));
    dispatch(setCurrentSelectedLevelOne(levelOne));
  }, [menuItems]);

  useEffect(() => {
    if (savedModulePath !== modulePath) {
      dispatch(setModulePath(modulePath));
      dispatch(clearAllFilters());
    }
  }, [modulePath]);

  useEffect(() => {
    if (savedModulePath === modulePath) {
      const promise = dispatch(fetchCrudList({ endpoint, filters }));

      return () => {
        promise.abort();
      };
    }
  }, [filters, modulePath, currentLanguage]);

  useEffect(() => {
    if (items?.length === checkedCheckboxIdRow?.length && items?.length > 0) {
      setCheckedCheckboxAll(true);
    } else {
      setCheckedCheckboxAll(false);
    }

    if (checkedCheckboxIdRow.length > 0) {
      dispatch(setCloneOrCopyButtonDisabled(false));
    } else {
      dispatch(setCloneOrCopyButtonDisabled(true));
    }
  }, [checkedCheckboxIdRow, items]);

  const handleCheckAllCheckboxes = (e) => {
    const allCheckboxChecked = e.target.checked;
    if (allCheckboxChecked && items?.length > 0) {
      const tmpArray = [];

      for (const item of items) {
        tmpArray.push(item.id);
      }
      setCheckedCheckboxIdRow(tmpArray);
    } else {
      setCheckedCheckboxIdRow([]);
    }
  };

  const handleCheckOneCheckbox = (e, row) => {
    const checkboxChecked = e.target.checked;
    if (checkboxChecked) {
      setCheckedCheckboxIdRow((prevState) => {
        setCheckedCheckboxIdRow([...prevState, row.id]);
      });
    } else {
      const tmpArray = checkedCheckboxIdRow.filter((item) => {
        return item !== row.id;
      });
      setCheckedCheckboxIdRow(tmpArray);
    }
  };

  const handleDelete = () => {
    const data = checkedCheckboxIdRow;
    dispatch(setListDeleteDialogOpen(false));

    backend.post(endpoint + "deleteMany", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        setCheckedCheckboxIdRow([]);
        dispatch(fetchCrudList({ endpoint, filters }));
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const handleClone = () => {
    const data = checkedCheckboxIdRow;
    dispatch(setListCloneDialogOpen(false));

    backend.post(endpoint + "cloneMany", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        setCheckedCheckboxIdRow([]);
        dispatch(fetchCrudList({ endpoint, filters }));
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  if (error.isError) {
    return <Error error={error} />;
  }
  return (
    <div className={classes.root}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer className={classes.styledTable}>
            <Table className={classes.table} aria-label="table">
              <DataTableHead
                headCells={headCells}
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                handleCheckAllCheckboxes={handleCheckAllCheckboxes}
                checkedCheckboxAll={checkedCheckboxAll}
              />
              <TableBody>
                {items?.length === 0 && <RowNoData colSpan={headCells?.length + 1} />}
                {items.map((row, i) => (
                  <DataTableProductCategoryRow
                    key={row.id}
                    row={row}
                    checkedCheckboxIdRow={checkedCheckboxIdRow}
                    headCells={headCells}
                    languages={languages}
                    handleCheckOneCheckbox={handleCheckOneCheckbox}
                    handleClick={handleClick}
                    handleClickLanguageAction={handleClickLanguageAction}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={parseInt(totalItems)}
            rowsPerPage={limit}
            page={parseInt(page)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("tp_rows_per_page")}
            labelDisplayedRows={({ from, to, count }) => {
              return "" + from + "-" + to + t("tp_from") + count;
            }}
          />
        </>
      )}
      <ConfirmationDialog
        open={deleteDialogOpen}
        close={() => dispatch(setListDeleteDialogOpen(false))}
        confirm={handleDelete}
      />
      <ConfirmationDialog
        open={cloneDialogOpen}
        close={() => dispatch(setListCloneDialogOpen(false))}
        confirm={handleClone}
        customTitle={"confirmation_modal_title_clone"}
      />
    </div>
  );
};
export default DataTableProductCategory;
