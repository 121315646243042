import { Grid, Paper } from "@mui/material";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { productAttributeTypes } from "modules/ShopCatalog/ProductObjs";
import ProductAttributesLabelsPanel from "components/Edit/Product/ProductAttributesLabelsPanel";
import ProductAttributesMultiSelect from "components/Edit/Product/ProductAttributesMultiSelect";
import { useFormContext } from "react-hook-form";

const endpoint = "/attributes/";
const title = "shop_product_attributes_edit_label";
const moduleUrl = "/shop/attributes/";

const ProductAttributeEdit = () => {
  const { t } = useTranslation("common");
  const current = useSelector(selectEditItem);

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")).nullable(),
    type: Yup.string().trim().required(t("form-validation-required")).nullable(),
    typesProducts: Yup.array().nullable(),
    labels: Yup.array().nullable(),
  });

  const defaultValueForm = {
    name: "",
    type: "",
    typesProducts: [],
    labels: [],
  };

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        defaultValue={defaultValueForm}
        deleteButtonTitle={"Usuń Atrybut"}
      >
        <FormData current={current} />
      </EditViewLanguage>
    </>
  );
};

const FormData = () => {
  const { watch } = useFormContext();
  const watchType = watch("type");

  return (
    <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <CustomizedTextField label={"Nazwa"} name={"name"} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedSelect name="type" label="Typ pola" items={productAttributeTypes} noNullValue />
        </Grid>
        <Grid item xs={12}>
          <ProductAttributesMultiSelect />
        </Grid>
        {(watchType === "STRING_VALUE" || watchType === "SELECT_VALUE") && (
          <Grid item xs={12}>
            <ProductAttributesLabelsPanel label={"Etykiety"} name={"labels"} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ProductAttributeEdit;
