import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "components/Edit/DnD/ItemTypes.js";
import TableCell from "@mui/material/TableCell";
import { Checkbox } from "@mui/material";
import SliderTableCellActions from "components/Edit/Slider/SliderTableCellActions";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
export const RowDnDPriceMonitoringItem = ({
  id,
  item,
  index,
  handleMoveItem,
  checked,
  handleCheched,
  handleEdit,
  handleRefresh,
  isRefreshing,
}) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      handleMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.15 : 1;
  drag(drop(ref));
  const formatPrice = (price) => {
    if (price === null || price === undefined) {
      return "0.00";
    }
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  return (
    <TableRow style={{ opacity }} ref={preview} data-handler-id={handlerId}>
      <TableCell>
        <Checkbox onClick={(e) => handleCheched(e, id)} checked={checked} />
      </TableCell>
      <TableCell>{item.url.length > 120 ? item.url.substring(0, 117) + "..." : item.url}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          {!isRefreshing && <RefreshIcon onClick={(e) => handleRefresh(e, id)} />}
          {isRefreshing && <CircularProgress size="25px" sx={{ color: "orange" }} />}
          {item.hasError && (
            <Box mr={2} gap={2}>
              <Tooltip title={item.errorMessage}>
                <ErrorIcon sx={{ color: "orange" }} />
              </Tooltip>
            </Box>
          )}
          {formatPrice(item.currentPrice)} PLN
        </Box>
      </TableCell>
      <SliderTableCellActions idItem={id} drag={ref} handleEdit={handleEdit} />
    </TableRow>
  );
};
