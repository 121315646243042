import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomSingleDatePicker from "components/CustomDatePicker/CustomSingleDatePicker";
import moment from "moment";
import { setAttribute, setListPage } from "redux/slices/crud";
import { Box, margin } from "@mui/system";

const HeaderDateSelector = ({ itemData }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [selectValue, setSelectValue] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const handleChangeDate = (date) => {
    const dateValue = moment(date).format("YYYY-MM-DD");
    setSelectValue(dateValue);
    const value = { name: "_filterByDate", value: dateValue };
    dispatch(setListPage(0));
    dispatch(setAttribute(value));
  };

  return (
    <Box style={{ margin: "0px 10px" }}>
      <CustomSingleDatePicker
        placeholder={"Wybierz date"}
        date={moment(selectValue, "YYYY-MM-DD")}
        setDate={handleChangeDate}
      />
    </Box>
  );
};
export default HeaderDateSelector;
