import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import SliderToolbar from "components/Edit/Slider/SliderToolbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as color from "assets/styles/jss/common/colors";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RowDnDMenu } from "components/Edit/DnD/RowDnDMenu";
import ConfirmationDialog from "components/ConfirmationDialog";
import { Typography } from "@mui/material";
import NewPriceMonitoringItemDialog from "components/Edit/PriceMonitoring/NewPriceMonitoringItemDialog";
import { RowDnDPriceMonitoringItem } from "components/Edit/PriceMonitoring/RowDnDPriceMonitoringItem";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { set } from "lodash";

// type PriceMonitoringItem = {
//   requestType: string,
//   sessionID: string,
//   data: {
//     priceMonitoringItemID: number,
//     priceMonitoringID: number,
//     url: string,
//     selectorType: string,
//     selectorContent: string,
//     scenarioFileName: string,
//     scenarioFileContent: string,
//     loadDelay: number,
//   },
//   result: {
//     valueNumeric: number,
//     valueText: string,
//     isError: boolean,
//     errorMessage: string,
//   },
// };

const PriceMonitoringItemsPanel = ({ label, name, parentId, currentPrice }) => {
  const { control, setValue, formState } = useFormContext();
  const { fields, append } = useFieldArray({
    name: name,
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: "idUseField",
  });
  const [listCheckedItem, setListCheckedItem] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [cloneOrCopyButtonDisabled, setCloneOrCopyButtonDisabled] = useState(true);
  const [newItemId, setNewItemId] = useState(-1);
  const [itemToEdit, setItemToEdit] = useState(null);

  // console.log("formState", formState.errors);

  useEffect(() => {
    if (listCheckedItem.length > 0) {
      setCloneOrCopyButtonDisabled(false);
    } else {
      setCloneOrCopyButtonDisabled(true);
    }
  }, [listCheckedItem]);

  const moveItem = (dragIndex, hoverIndex) => {
    //move(dragIndex, hoverIndex); // slow

    Array.prototype.MyMove = function (from, to) {
      this.splice(to, 0, this.splice(from, 1)[0]);
      return this;
    };

    fields.MyMove(dragIndex, hoverIndex);

    for (const i in fields) {
      fields[i].sortOrder = i;
    }

    setValue(name, fields);
  };

  const handleCheched = (e, id) => {
    const checkboxChecked = e.target.checked;
    if (checkboxChecked) {
      setListCheckedItem((prevState) => {
        setListCheckedItem([...prevState, id]);
      });
    } else {
      const tmpArray = listCheckedItem.filter((item) => {
        return item !== id;
      });
      setListCheckedItem(tmpArray);
    }
  };

  const handleDelete = () => {
    //del item from edit dialog
    if (itemToDelete) {
      const index = fields.findIndex((object) => {
        return object.id === itemToDelete.id;
      });

      //remove(index); //slow
      fields.splice(index, 1);
      setValue(name, fields);

      setListCheckedItem([]);
      setItemToDelete(null);
      setDeleteDialogOpen(false);

      return;
    }

    //del checked items
    for (const item of listCheckedItem) {
      const index = fields.findIndex((object) => {
        return object.id === item;
      });

      fields.splice(index, 1);
    }

    setValue(name, fields);

    setListCheckedItem([]);
    setDeleteDialogOpen(false);
  };

  const handleCanelDelete = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  const handleClone = () => {
    let newId = newItemId;
    const itemsToAdd = [];
    let nextOrder = fields?.length > 0 ? fields[fields.length - 1].sortOrder + 1 : 0;

    for (const item of listCheckedItem) {
      const itemToClone = fields.find((object) => {
        return object.id === item;
      });

      const itemToAdd = Object.assign({}, itemToClone);

      itemToAdd.id = newId;
      newId -= 1;
      itemToAdd.sortOrder = nextOrder;
      nextOrder += 1;
      itemToAdd.label = "Kopia - " + itemToAdd.label;
      itemsToAdd.push(itemToAdd);
    }

    //append(itemsToAdd); //slow
    fields.push(...itemsToAdd);
    setValue(name, fields);

    setNewItemId(newId);
    setListCheckedItem([]);
    setCloneDialogOpen(false);
  };

  const handleAddOrEditPriceMonitoringItem = (item) => {
    setAddDialogOpen(false);

    if (Object.hasOwn(item, "id")) {
      setItemToEdit(null);
      const indexToUpdate = fields.findIndex((object) => {
        return object.id === item.id;
      });
      fields[indexToUpdate] = item;
      setValue(name, fields);
      //update(indexToUpdate, item); //slow
    } else {
      const nextOrder = fields?.length > 0 ? fields[fields.length - 1].sortOrder + 1 : 0;
      //append({ ...item, id: newItemId, order: nextOrder }); //slow
      fields.push({ ...item, id: newItemId, sortOrder: nextOrder });
      setValue(name, fields);

      setNewItemId((prevState) => prevState - 1);
    }
  };

  const handleEditOpenDialog = (id) => {
    setItemToEdit(
      fields.find((object) => {
        return object.id === id;
      }),
    );
    setAddDialogOpen(true);
  };

  const handleRemoveFromEditDialog = (item) => {
    setItemToDelete(item);
    setDeleteDialogOpen(true);
  };

  const [socketUrl, setSocketUrl] = useState(`//${process.env.REACT_APP_WS_URL}/ws`);

  const [messages, setMessages] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [refeshingItems, setRefreshingItems] = useState(
    fields.reduce((acc, item) => {
      return { ...acc, [item.id]: false };
    }, {}),
  );

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (lastMessage !== null) {
      setMessages((prev) => prev.concat(lastMessage));
      const response = JSON.parse(lastMessage.data);
      console.log("data", response);
      const sessionId = response.sessionID;
      if (sessions.includes(sessionId)) {
        const item = fields.find((o) => o.id === response.data.priceMonitoringItemID);
        item.currentPrice = response.result.valueNumeric;
        item.hasError = response.result.isError;
        setRefreshingItems((prev) => ({ ...prev, [response.data.priceMonitoringItemID]: false }));
      }
    }
  }, [lastMessage]);

  const handleRefresh = async (e, id) => {
    const sessionID = Math.floor(Math.random() * 1000000).toString();
    setSessions((prev) => [...prev, sessionID]);
    const item = fields.find((o) => o.id === id);

    const body = {
      sessionID: sessionID,
      priceMonitoringItemID: id,
      priceMonitoringID: parseInt(parentId),
      url: item.url,
      crawlerType: item.crawlerType,
      selectorType: item.selectorType,
      selectorContent: item.selectorContent,
      scenarioFileName: item.scenarioFileName,
      scenarioFileContent: item.scenarioFileContent,
      loadDelay: item.loadDelay,
    };
    const x = await fetch(`//${process.env.REACT_APP_WS_URL}/api/v1/test`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (x.ok) {
      setRefreshingItems((prev) => ({ ...prev, [id]: true }));
    }
    return;
  };
  console.log("connectionStatus", connectionStatus);
  console.log("process", process.env.REACT_APP_WS_URL);

  return (
    <>
      <SliderToolbar
        label={label}
        labelButton={"Dodaj element"}
        buttonAction={() => setAddDialogOpen(true)}
        handleClone={() => setCloneDialogOpen(true)}
        handleDelete={() => setDeleteDialogOpen(true)}
        cloneOrCopyButtonDisabled={cloneOrCopyButtonDisabled}
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ borderTop: "1px solid" + color.black }}>
              <TableCell></TableCell>
              <TableCell>Url produktu konkurencji</TableCell>
              <TableCell>Cena dziś</TableCell>
              <TableCell>
                Nasza cena:
                <br /> {currentPrice}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DndProvider backend={HTML5Backend}>
              {fields?.map((section, index) => (
                <RowDnDPriceMonitoringItem
                  key={section.id}
                  index={index}
                  id={section.id}
                  item={section}
                  handleMoveItem={moveItem}
                  handleCheched={handleCheched}
                  handleEdit={handleEditOpenDialog}
                  checked={listCheckedItem.includes(section.id) ? true : false}
                  handleRefresh={handleRefresh}
                  isRefreshing={refeshingItems[section.id]}
                />
              ))}
            </DndProvider>
          </TableBody>
        </Table>
        {fields?.length === 0 && <Typography>Brak elementów</Typography>}
      </TableContainer>
      <NewPriceMonitoringItemDialog
        open={addDialogOpen}
        handleConfirm={handleAddOrEditPriceMonitoringItem}
        handleRemove={handleRemoveFromEditDialog}
        itemToEdit={itemToEdit}
        handleClose={() => {
          setAddDialogOpen(false);
          setItemToEdit(null);
        }}
      />
      <ConfirmationDialog open={deleteDialogOpen} close={handleCanelDelete} confirm={handleDelete} />
      <ConfirmationDialog
        open={cloneDialogOpen}
        close={() => setCloneDialogOpen(false)}
        confirm={handleClone}
        customTitle={"confirmation_modal_title_clone"}
      />
    </>
  );
};

export default PriceMonitoringItemsPanel;
