import { Grid, Paper } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import EditViewLanguage from "components/EditViewLanguage";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import backend from "api/backend";
import { isSuccess } from "utils/http";
const CategoryEditListDialogControled = React.lazy(() =>
  import("modules/CMSDocuments/CategoryEditListDialogControled"),
);
const ProductEditForm = React.lazy(() => import("modules/ShopCatalog/ProductEditForm"));
import { productFamilyTypes } from "modules/ShopCatalog/ProductObjs";
import CircularProgress from "@mui/material/CircularProgress";

const endpoint = "/product/";
const title = "shop_product_edit_label";
const moduleUrl = "/shop/products/";

function isSpecialProduct(id) {
  return id == 13300;
}

const ProductEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    productTypeFamily: Yup.string().required(t("form-validation-required")),

    name: Yup.string().trim().required(t("form-validation-required")),
    shortName: Yup.string().nullable(),
    url: isSpecialProduct(id) ? Yup.string().nullable() : Yup.string().required(t("form-validation-required")),
    active: Yup.bool().required(t("form-validation-required")),
    excludeFromSiteMap: Yup.bool().nullable(),
    icon: Yup.string().nullable(),
    image: Yup.string().nullable(),
    imageAltText: Yup.string().trim().nullable(),
    iconAltText: Yup.string().trim().nullable(),

    metaTitle: Yup.string().nullable(),
    metaDescription: Yup.string().nullable(),
    introBoldEdit: Yup.string().nullable(),
    introEdit: Yup.string().nullable(),

    sku: Yup.string().nullable(),
    ean: Yup.string().nullable(),
    categoryId: Yup.number().typeError(t("form-validation-required")).required(t("form-validation-required")),
    technology: Yup.string().nullable(),
    resultTime: Yup.string().nullable(),
    sectionTitle: Yup.string().nullable(),
    productType: Yup.string().trim().nullable(),
    laboratory: Yup.string().trim().nullable(),
    orderWorkflow: Yup.string().trim().nullable(),
    newProductBadge: Yup.bool().typeError(t("form-validation-required")),
    communicationWorkflow: Yup.number().nullable(true),
    activeStartDate: Yup.string().trim().nullable(),
    activeEndDate: Yup.string().trim().nullable(),

    needPackage: Yup.boolean().typeError(t("form-validation-required")),
    codes: Yup.string().nullable(),
    retailPrice: Yup.string().required(t("form-validation-required")),
    salePrice: Yup.string().nullable(),
    tax: Yup.string().required(t("form-validation-required")),
    temporarilyUnavailable: Yup.bool().typeError(t("form-validation-required")),
    productLimit: Yup.number().typeError(t("form-validation-number")).integer(t("form-validation-number")),
    searchWeight: Yup.string().nullable(),
    promotionDiscountCodeId: Yup.string().trim().nullable(),
    promotionDiscountCodeItemId: Yup.string()
      .when("promotionDiscountCodeId", {
        is: (p) => !p || p === "",
        then: Yup.string().trim().nullable(),
      })
      .when("promotionDiscountCodeId", {
        is: (p) => p && p !== "",
        then: Yup.string().trim().required(t("form-validation-required")),
      }),
    stockLevel: Yup.number()
      .typeError(t("form-validation-number"))
      .integer(t("form-validation-number"))
      .required(t("form-validation-required")),
    stockAllowOutOfStockPurchase: Yup.bool().required(t("form-validation-required")),
    feature: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        value: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
    upSells: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        productId: Yup.string().nullable(),
      }),
    ),
    crossSells: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        productId: Yup.string().nullable(),
      }),
    ),
    inPackages: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        productId: Yup.string().nullable(),
      }),
    ),
    searchTags: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
    ),
    negativeSearchTags: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
    ),
    tabs: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        name: Yup.string().nullable(),
        content: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
    productAttributes: Yup.array(),
  });

  const defaultValue = {
    productTypeFamily: "",

    name: "",
    shortName: "",
    url: "",
    active: true,
    excludeFromSiteMap: false,
    icon: "",
    image: "",
    imageAltText: "",
    iconAltText: "",

    metaTitle: "",
    metaDescription: "",
    intro: "",
    introList: "",
    introBoldEdit: "",
    introEdit: "",

    sku: "",
    ean: "",
    categoryId: "",
    technology: "",
    resultTime: "",
    sectionTitle: "",
    productType: "",
    laboratory: "",
    orderWorkflow: "",
    newProductBadge: null,
    communicationWorkflow: null,
    activeStartDate: "",
    activeEndDate: "",

    needPackage: null,
    codes: "",
    retailPrice: "",
    salePrice: "",
    tax: "",
    temporarilyUnavailable: null,
    productLimit: 0,
    searchWeight: "",
    promotionDiscountCodeId: "",
    promotionDiscountCodeItemId: "",
    stockLevel: 0,
    stockAllowOutOfStockPurchase: true,
    feature: [],
    upSells: [],
    crossSells: [],
    inPackages: [],
    searchTags: [],
    negativeSearchTags: [],
    tabs: [],
    productAttributes: [],
  };

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        preview={true}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Produkt"}
      >
        <FormData id={id} current={current} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current }) => {
  const { control, setValue, getValues } = useFormContext();

  const [allActiveCategory, setAllActiveCategory] = useState([]);
  const [allActiveGroup, setAllActiveGroup] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);

  const [openedDialogUpSell, setOpenedDialogUpSell] = useState(false);
  const [openedDialogCrossSell, setOpenedDialogCrossSell] = useState(false);
  const [openedDialogInPackages, setOpenedDialogInPackages] = useState(false);

  const [isAllProductReceived, setIsAllProductReceived] = useState(false);

  const [allAttributes, setAllAttributes] = useState([]);
  const [attributesMedical, setAttributesMedical] = useState([]);
  const [attributesDrugsAndSupplements, setAttributesDrugsAndSupplements] = useState([]);
  const [attributesPhysicalProducts, setAttributesPhysicalProducts] = useState([]);
  const [attributesVirtualProducts, setAttributesVirtualProducts] = useState([]);
  const [attributesConsultations, setAttributesConsultations] = useState([]);
  const [attributesNonStandardProducts, setAttributesNonStandardProducts] = useState([]);
  const [isAllAttributesReceived, setIsAllAttributesReceived] = useState(false);
  const [openedDialogAttributes, setOpenedDialogAttributes] = useState(false);

  const upSellList = useWatch({
    control,
    name: `upSells`,
  });
  const crossSellList = useWatch({
    control,
    name: `crossSells`,
  });

  const inPackagesList = useWatch({
    control,
    name: `inPackages`,
  });

  const productTypeFamilyWatch = useWatch({
    control,
    name: `productTypeFamily`,
  });

  useEffect(() => {
    backend.get("/category/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const allCategory = res.data;
          const activeCategory = allCategory.filter((item) => {
            return item.active;
          });
          const category = getValues("categoryId");

          if (
            !category ||
            activeCategory.find(function (element) {
              return element.id == category;
            })
          ) {
            setAllActiveCategory(activeCategory);
          } else if (
            allCategory.find(function (element) {
              return element.id == category;
            })
          ) {
            setAllActiveCategory([
              ...activeCategory,
              ...allCategory.filter((item) => {
                return item.id == category;
              }),
            ]);
          } else {
            setAllActiveCategory([...activeCategory, { id: category, displayName: "Item removed?", active: false }]);
          }
        }
      }
    });
    return () => {
      setAllActiveCategory([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/communication-group/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const allGroup = res.data;
          const activeGroup = allGroup.filter((item) => {
            return item.active;
          });
          const group = getValues("communicationWorkflow");

          if (
            !group ||
            activeGroup.find(function (element) {
              return element.id == group;
            })
          ) {
            setAllActiveGroup(activeGroup);
          } else if (
            allGroup.find(function (element) {
              return element.id == group;
            })
          ) {
            setAllActiveGroup([
              ...activeGroup,
              ...allGroup.filter((item) => {
                return item.id == group;
              }),
            ]);
          } else {
            setAllActiveGroup([...activeGroup, { id: group, displayName: "Item removed?", active: false }]);
          }
        }
      }
    });
    return () => {
      setAllActiveGroup([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/product/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setActiveProducts(
            res.data.filter((item) => {
              return item.active;
            }),
          );
          setAllProducts(res.data);
        }
        setIsAllProductReceived(true);
      }
    });
    return () => {
      setAllProducts([]);
    };
  }, []);

  useEffect(() => {
    backend
      .get("/attributes/getAll")
      .then((res) => {
        if (isSuccess(res)) {
          const data = res.data;
          if (data.length > 0) {
            setAllAttributes(data);
            setAttributesMedical(data.filter((item) => item?.productTypes?.includes("MEDICAL_EXAMINATIONS")));
            setAttributesDrugsAndSupplements(
              data.filter((item) => item?.productTypes?.includes("DRUGS_AND_SUPPLEMENTS")),
            );
            setAttributesPhysicalProducts(data.filter((item) => item?.productTypes?.includes("PHYSICAL_PRODUCTS")));
            setAttributesVirtualProducts(data.filter((item) => item?.productTypes?.includes("VIRTUAL_PRODUCTS")));
            setAttributesConsultations(data.filter((item) => item?.productTypes?.includes("CONSULTATIONS")));
            setAttributesNonStandardProducts(
              data.filter((item) => item?.productTypes?.includes("NON_STANDARD_PRODUCTS")),
            );
          }
        }
      })
      .finally(() => setIsAllAttributesReceived(true));
    return () => {
      setAllProducts([]);
    };
  }, []);

  useEffect(() => {
    if (upSellList?.length > 0 && allProducts?.length > 0) {
      const newFields = [];

      for (const item of upSellList) {
        const searchItem = allProducts.filter((x) => x.id == item.id);
        if (searchItem && searchItem.length > 0) {
          newFields.push({ ...item, ...searchItem[0] });
        } else {
          newFields.push({ ...item, name: "Item removed?" });
        }
      }

      setValue("upSells", newFields);
    }

    if (crossSellList?.length > 0 && allProducts?.length > 0) {
      const newFields = [];

      for (const item of crossSellList) {
        const searchItem = allProducts.filter((x) => x.id == item.id);
        if (searchItem && searchItem.length > 0) {
          newFields.push({ ...item, ...searchItem[0] });
        } else {
          newFields.push({ ...item, name: "Item removed?" });
        }
      }

      setValue("crossSells", newFields);
    }

    if (inPackagesList?.length > 0 && allProducts?.length > 0) {
      const newFields = [];

      for (const item of inPackagesList) {
        const searchItem = allProducts.filter((x) => x.id == item.id);
        if (searchItem && searchItem.length > 0) {
          newFields.push({ ...item, ...searchItem[0] });
        } else {
          newFields.push({ ...item, name: "Item removed?" });
        }
      }

      setValue("inPackages", newFields);
    }
  }, [current, allProducts]);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedSelect
              label={"Typ produktu"}
              name={"productTypeFamily"}
              items={productFamilyTypes}
              noNullValue
            />
          </Grid>
        </Grid>
      </Paper>
      {productTypeFamilyWatch && (
        <Suspense fallback={<CircularProgress />}>
          <ProductEditForm
            id={id}
            allActiveCategory={allActiveCategory}
            allActiveGroup={allActiveGroup}
            setOpenedDialogCrossSell={setOpenedDialogCrossSell}
            crossSellList={crossSellList}
            setOpenedDialogInPackages={setOpenedDialogInPackages}
            inPackagesList={inPackagesList}
            isAllProductReceived={isAllProductReceived}
            upSellList={upSellList}
            setOpenedDialogUpSell={setOpenedDialogUpSell}
            productTypeFamilyWatch={productTypeFamilyWatch}
            setOpenedDialogAttributes={setOpenedDialogAttributes}
            isAllAttributesReceived={isAllAttributesReceived}
            allAttributes={allAttributes}
          />
        </Suspense>
      )}
      {isAllProductReceived && (
        <Suspense fallback={<CircularProgress />}>
          <CategoryEditListDialogControled
            handleClose={() => setOpenedDialogUpSell(false)}
            open={openedDialogUpSell}
            allItemsTable={Array.isArray(activeProducts) ? activeProducts : []}
            nameFormTables={"upSells"}
            nameToDisplay={"name"}
            setFieldsToRightColumnFromAllItems={false}
          />
        </Suspense>
      )}
      {isAllProductReceived && (
        <Suspense fallback={<CircularProgress />}>
          <CategoryEditListDialogControled
            handleClose={() => setOpenedDialogCrossSell(false)}
            open={openedDialogCrossSell}
            allItemsTable={Array.isArray(activeProducts) ? activeProducts : []}
            nameFormTables={"crossSells"}
            nameToDisplay={"name"}
            setFieldsToRightColumnFromAllItems={false}
          />
        </Suspense>
      )}
      {isAllProductReceived && (
        <Suspense fallback={<CircularProgress />}>
          <CategoryEditListDialogControled
            handleClose={() => setOpenedDialogInPackages(false)}
            open={openedDialogInPackages}
            allItemsTable={Array.isArray(activeProducts) ? activeProducts : []}
            nameFormTables={"inPackages"}
            nameToDisplay={"name"}
            setFieldsToRightColumnFromAllItems={false}
          />
        </Suspense>
      )}
      {isAllAttributesReceived && (
        <Suspense fallback={<CircularProgress />}>
          <CategoryEditListDialogControled
            handleClose={() => setOpenedDialogAttributes(false)}
            open={openedDialogAttributes}
            allItemsTable={
              productTypeFamilyWatch === "MEDICAL_EXAMINATIONS"
                ? attributesMedical
                : productTypeFamilyWatch === "DRUGS_AND_SUPPLEMENTS"
                ? attributesDrugsAndSupplements
                : productTypeFamilyWatch === "PHYSICAL_PRODUCTS"
                ? attributesPhysicalProducts
                : productTypeFamilyWatch === "VIRTUAL_PRODUCTS"
                ? attributesVirtualProducts
                : productTypeFamilyWatch === "CONSULTATIONS"
                ? attributesConsultations
                : productTypeFamilyWatch === "NON_STANDARD_PRODUCTS"
                ? attributesNonStandardProducts
                : []
            }
            nameFormTables={"productAttributes"}
            nameToDisplay={"name"}
            keepSortOrder
          />
        </Suspense>
      )}
    </>
  );
};

export default ProductEdit;
