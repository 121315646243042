import { Grid, LinearProgress, Paper, TableContainer, TableHead } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import backend from "api/backend";
import { isSuccess } from "utils/http";

const ABStatistic = ({ id }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    backend.get(`/test-ab/statistic/${id}`).then((value) => {
      if (isSuccess(value)) {
        setData(value.data);
      }
    });
  }, [id]);

  return (
    <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
      <Grid container spacing={5}>
        {data != null ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">Wariant A</TableCell>
                  <TableCell align="right">Wariant B</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Przydzielono
                  </TableCell>
                  <TableCell align="right">{data.assignedA}</TableCell>
                  <TableCell align="right">{data.assignedB}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sukcesy
                  </TableCell>
                  <TableCell align="right">{data.successesA}</TableCell>
                  <TableCell align="right">{data.successesB}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Suma zakupów
                  </TableCell>
                  <TableCell align="right">{parseFloat(data.totalA).toFixed(2)}</TableCell>
                  <TableCell align="right">{parseFloat(data.totalB).toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Współczynnik konwersji
                  </TableCell>
                  <TableCell align="right">{parseFloat(data.conversionRateA).toFixed(4)}</TableCell>
                  <TableCell align="right">{parseFloat(data.conversionRateB).toFixed(4)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Średni zakup
                  </TableCell>
                  <TableCell align="right">{parseFloat(data.averageA).toFixed(2)}</TableCell>
                  <TableCell align="right">{parseFloat(data.averageB).toFixed(2)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <LinearProgress sx={{ width: "100%", mt: 6 }} />
        )}
      </Grid>
    </Paper>
  );
};

export default ABStatistic;
