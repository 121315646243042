import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import Wysiwyg from "components/Edit/Wysiwyg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useFormContext } from "react-hook-form";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import CustomizedMetaTextField from "components/Edit/CustomizedMetaTextField";

const endpoint = "/page/";
const title = "cms_page_edit_label";
const moduleUrl = "/cms/pages/";

const PageEdit = () => {
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    url: Yup.string().trim().required(t("form-validation-required")),
    offline: Yup.bool().nullable(),
    metaTitle: Yup.string().nullable(),
    metaDescription: Yup.string().nullable(),
    content: Yup.string().nullable(),
    menuId: Yup.string().nullable(),
    excludeFromSiteMap: Yup.bool().nullable(),
  });

  const defaultValue = {
    name: "",
    url: "",
    offline: "true",
    metaTitle: "",
    metaDescription: "",
    content: "",
    excludeFromSiteMap: false,
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        preview={true}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Stronę"}
      >
        <FormData />
      </EditViewLanguage>
    </>
  );
};

const FormData = () => {
  const { t } = useTranslation("common");
  const { getValues } = useFormContext();
  const [allMenus, setAllMenus] = useState([]);

  useEffect(() => {
    backend.get("/cms-menu/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const menus = res.data;
          const menu = getValues("menuId");

          if (
            !menu ||
            menus.find(function (element) {
              return element.id === menu;
            })
          ) {
            setAllMenus(menus);
          } else {
            setAllMenus([...menus, { id: menu, displayName: "Item removed?" }]);
          }
        }
      }
    });
  }, []);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"URL"} name={"url"} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomizedSwitch
              name={"offline"}
              rightLabel={t("cms_edit_page_offline")}
              topLabel={t("cms_edit_page_state")}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomizedSwitch name={"excludeFromSiteMap"} topLabel={"Usuń z sitemapy"} rightLabel={"Tak"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect label={"Menu"} name={"menuId"} items={allMenus} />
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  {t("cms_edit_seo_data")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <CustomizedMetaTextField label={"Meta title"} name={"metaTitle"} type="TITLE" />
                <br />
                <CustomizedMetaTextField label={"Meta description"} name={"metaDescription"} type="DESCRIPTION" />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  {t("cms_edit_page_content")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <Grid item xs={12}>
                  <Wysiwyg name={"content"} />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PageEdit;
