import { Checkbox, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataTableHead } from "./DataTableHead";
import { HEAD_CELL_TYPES } from "../../enums/headCellTypes";
import CellActions from "components/Table/CellActions";
import CellLanguageActions from "components/Table/CellLanguageActions";
import CellMiniature from "components/Table/CellMiniature";
import CellNumberInvoice from "components/Table/CellNumberInvoice";
import CellMarketingConsents from "components/Table/CellMarketingConsents";
import CellAttachment from "components/Table/CellAttachment";
import CellLanguageName from "components/Table/CellLanguageName";
import ErrorIcon from "@mui/icons-material/Error";
import {
  clearAllFilters,
  fetchCrudList,
  selectListError,
  selectListFilters,
  selectListHeadCells,
  selectListInfo,
  selectListIsLoading,
  selectListItems,
  selectListToolbar,
  setListLimit,
  setListOrder,
  setListOrderBy,
  setListPage,
} from "redux/slices/crud";
import {
  selectListCloneDialogOpen,
  selectListDeleteDialogOpen,
  setCloneOrCopyButtonDisabled,
  setListCloneDialogOpen,
  setListDeleteDialogOpen,
} from "redux/slices/materials";
import {
  selectMenuItems,
  selectModulePath,
  setCurrentSelectedLevelOne,
  setCurrentSelectedLevelZero,
  setModulePath,
} from "redux/slices/menu";
import {
  changeLanguage,
  fetchAvailableLangs,
  selectLangCode,
  selectLanguages,
  setCurrentLanguageCode,
} from "../../redux/slices/language";

import Error from "../Error";
import ConfirmationDialog from "components/ConfirmationDialog";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import RowNoData from "components/Table/RowNoData";
import CellStatus from "components/Table/CellStatus";
import { findMenu } from "utils/menu";
import CellProductType from "components/Table/CellProductType";
import CellInboxType from "components/Table/Inbox/CellInboxType";
import axios from "axios";
import getBackendHost from "utils/backendUrl";
import CellTranslate from "components/Table/CellTranslate";
import CellMapStatus from "components/Table/CellMapStatus";
import CellProductCategoryNameLevel from "components/Table/CellProductCategoryNameLevel";

const useStyles = makeStyles((theme) => ({}));

const defaultAdditionalParameters = {
  textFieldRow: false,
  noCheckboxColumn: false,
  noPagination: false,
  languageSupport: false,
  getInvoiceAfterClick: false,
};

const DataTable = ({
  modulePath,
  endpoint,
  isClickable = true,
  additionalParameters = defaultAdditionalParameters,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector(selectListFilters);
  const info = useSelector(selectListInfo);
  const headCells = useSelector(selectListHeadCells);
  const items = useSelector(selectListItems);
  const toolbarItems = useSelector(selectListToolbar);
  const isLoading = useSelector(selectListIsLoading);
  const error = useSelector(selectListError);
  const { page, limit, order, orderBy } = filters;
  const { totalItems } = info;
  const { enqueueSnackbar } = useSnackbar();
  const savedModulePath = useSelector(selectModulePath);
  const currentLanguage = useSelector(selectLangCode);

  const [checkedCheckboxIdRow, setCheckedCheckboxIdRow] = useState([]);
  const [checkedCheckboxAll, setCheckedCheckboxAll] = useState(false);

  const deleteDialogOpen = useSelector(selectListDeleteDialogOpen);
  const cloneDialogOpen = useSelector(selectListCloneDialogOpen);

  const languages = useSelector(selectLanguages);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setListOrder(isAsc ? "desc" : "asc"));
    dispatch(setListOrderBy(property));
  };
  const handleChangePage = (event, newPage) => {
    dispatch(setListPage(newPage));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setListLimit(parseInt(event.target.value, 10)));
    dispatch(setListPage(0));
  };
  const handleClick = (e, row) => {
    if (additionalParameters?.textFieldRow) {
      handleClickRow(e, row);
    } else if (isClickable && additionalParameters.getInvoiceAfterClick) {
      getInvoicePdf(row);
    } else if (isClickable && !additionalParameters?.languageSupport) {
      history.push(modulePath + "/" + row.id);
    } else if (isClickable && additionalParameters?.languageSupport) {
      changeLanguage("pl").then((r) => {
        if (r === true) {
          dispatch(setCurrentLanguageCode("pl"));
          history.push(modulePath + "/" + row.id + "/pl");
        }
      });
    }
  };

  const handleClickLanguageAction = (e, row, langCode) => {
    if (currentLanguage != langCode) {
      changeLanguage(langCode).then((r) => {
        if (r === true) {
          dispatch(setCurrentLanguageCode(langCode));
          history.push(modulePath + "/" + row.id + "/" + langCode);
        }
      });
    } else {
      history.push(modulePath + "/" + row.id + "/" + langCode);
    }
  };

  useEffect(() => {
    dispatch(fetchAvailableLangs());
  }, []);

  const menuItems = useSelector(selectMenuItems);
  useEffect(() => {
    const { levelZero, levelOne } = findMenu(menuItems);
    dispatch(setCurrentSelectedLevelZero(levelZero));
    dispatch(setCurrentSelectedLevelOne(levelOne));
  }, [menuItems]);

  useEffect(() => {
    if (savedModulePath !== modulePath) {
      dispatch(setModulePath(modulePath));
      dispatch(clearAllFilters());
    }
  }, [modulePath]);

  useEffect(() => {
    if (savedModulePath === modulePath) {
      const promise = dispatch(fetchCrudList({ endpoint, filters }));

      return () => {
        promise.abort();
      };
    }
  }, [filters, modulePath]);

  useEffect(() => {
    if (items?.length === checkedCheckboxIdRow?.length && items?.length > 0) {
      setCheckedCheckboxAll(true);
    } else {
      setCheckedCheckboxAll(false);
    }

    if (checkedCheckboxIdRow.length > 0) {
      dispatch(setCloneOrCopyButtonDisabled(false));
    } else {
      dispatch(setCloneOrCopyButtonDisabled(true));
    }
  }, [checkedCheckboxIdRow, items]);

  /* translation of expressions -- begin */
  const [selectedRow, setSelectedRow] = useState({});
  const [translateChange, setTranslateChange] = useState({});
  const [isTranslateToSave, setIsTranslateToSave] = useState(false);

  const handleClickRow = (e, row) => {
    setIsTranslateToSave(false);
    setTranslateChange({});
    setSelectedRow(row);
  };

  const handleChangeTranslate = (lang, text) => {
    let trans = {};
    trans[lang] = text;

    setTranslateChange((prev) => ({ ...prev, ...trans }));
  };

  const handleSaveRow = (row) => {
    setIsTranslateToSave(true);
    const toSave = {
      id: row.id,
      key: row.key,
      keys: [],
    };

    for (const lang in translateChange) {
      const changedKey = {
        lang: lang,
        text: translateChange[lang],
      };
      toSave.keys.push(changedKey);
    }

    if (toSave.keys.length > 0) {
      backend.post("/locales/save", toSave).then((response) => {
        const data = response.data;

        if (data === "OK") {
          enqueueSnackbar(t("common_status_successfully"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("common_status_error") + data, {
            variant: "error",
          });
        }
      });
    }

    setTranslateChange({});
    setSelectedRow({});
  };
  /* translation of expressions -- end */

  const handleCheckAllCheckboxes = (e) => {
    const allCheckboxChecked = e.target.checked;
    if (allCheckboxChecked && items?.length > 0) {
      const tmpArray = [];

      for (const item of items) {
        tmpArray.push(item.id);
      }
      setCheckedCheckboxIdRow(tmpArray);
    } else {
      setCheckedCheckboxIdRow([]);
    }
  };

  const handleCheckOneCheckbox = (e, row) => {
    const checkboxChecked = e.target.checked;
    if (checkboxChecked) {
      setCheckedCheckboxIdRow((prevState) => {
        setCheckedCheckboxIdRow([...prevState, row.id]);
      });
    } else {
      const tmpArray = checkedCheckboxIdRow.filter((item) => {
        return item !== row.id;
      });
      setCheckedCheckboxIdRow(tmpArray);
    }
  };

  const handleDelete = () => {
    const data = checkedCheckboxIdRow;
    dispatch(setListDeleteDialogOpen(false));

    backend.post(endpoint + "deleteMany", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        setCheckedCheckboxIdRow([]);
        dispatch(fetchCrudList({ endpoint, filters }));
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.data?.message, {
          variant: "error",
        });
      }
    });
  };

  const handleClone = () => {
    const data = checkedCheckboxIdRow;
    dispatch(setListCloneDialogOpen(false));

    backend.post(endpoint + "cloneMany", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        setCheckedCheckboxIdRow([]);
        dispatch(fetchCrudList({ endpoint, filters }));
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  function getInvoicePdf(row) {
    const url = getBackendHost() + "/invoice/get-pdf/" + row.id;
    const fileName = row.fileNamePdf ? row.fileNamePdf : "fv.pdf";

    axios
      .get(url, {
        withCredentials: true,
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }

  if (error.isError) {
    return <Error error={error} />;
  }
  return (
    <div className={classes.root}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer className={classes.styledTable}>
            <Table className={classes.table} aria-label="table">
              <DataTableHead
                headCells={headCells}
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                noCheckbox={additionalParameters?.noCheckboxColumn}
                handleCheckAllCheckboxes={handleCheckAllCheckboxes}
                checkedCheckboxAll={checkedCheckboxAll}
              />
              <TableBody>
                {items?.length === 0 && (
                  <RowNoData
                    colSpan={additionalParameters?.noCheckboxColumn ? headCells?.length : headCells?.length + 1}
                  />
                )}
                {items.map((row, i) => {
                  return (
                    <Tooltip
                      key={`tt-${i}`}
                      title={
                        row["tooltipText"] == null ? (
                          ""
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: row["tooltipText"] }} />
                        )
                      }
                      placement="bottom"
                      followCursor={true}
                    >
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={additionalParameters?.noCheckboxColumn ? { height: 50 } : {}}
                      >
                        {!additionalParameters?.noCheckboxColumn && (
                          <TableCell key={i} align="left">
                            <Checkbox
                              color={"default"}
                              size={"small"}
                              onClick={(e) => handleCheckOneCheckbox(e, row)}
                              checked={checkedCheckboxIdRow.includes(row.id)}
                            />
                          </TableCell>
                        )}
                        {headCells.map((hc, i) => {
                          if (hc.type === HEAD_CELL_TYPES.CATEGORY_LEVEL_NAME) {
                            return (
                              <CellProductCategoryNameLevel
                                key={i}
                                data={row[hc.id]}
                                row={row}
                                handleClick={handleClick}
                              />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.MARKETING_CONSENT) {
                            return (
                              <CellMarketingConsents key={i} data={row[hc.id]} row={row} handleClick={handleClick} />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.ACTION) {
                            return <CellActions key={i} data={row[hc.id]} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.LANGUAGE_ACTION) {
                            return (
                              <CellLanguageActions
                                key={i}
                                data={row[hc.id]}
                                row={row}
                                handleClick={handleClickLanguageAction}
                                languages={languages}
                              />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.MINIATURE) {
                            return <CellMiniature key={i} data={row[hc.id]} row={row} handleClick={handleClick} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.INVOICE_NUMBER) {
                            return <CellNumberInvoice key={i} data={row[hc.id]} row={row} handleClick={handleClick} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.ATTACHMENT) {
                            return <CellAttachment key={i} data={row[hc.id]} row={row} handleClick={handleClick} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.LANGUAGE_NAME) {
                            return <CellLanguageName key={i} data={row[hc.id]} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.STATUS || hc.type === HEAD_CELL_TYPES.STATUS_INVERTED) {
                            return (
                              <CellStatus
                                key={i}
                                data={row[hc.id]}
                                type={hc.type}
                                row={row}
                                handleClick={handleClick}
                              />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.PRODUCT_TYPE) {
                            return (
                              <CellProductType
                                key={i}
                                data={row[hc.id]}
                                type={hc.type}
                                row={row}
                                handleClick={handleClick}
                              />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.MAP_STATUS) {
                            return (
                              <CellMapStatus
                                key={i}
                                data={row[hc.id]}
                                type={hc.type}
                                row={row}
                                handleClick={handleClick}
                              />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.INBOX) {
                            return (
                              <CellInboxType
                                key={i}
                                data={row[hc.id]}
                                type={hc.type}
                                row={row}
                                getData={() => {
                                  dispatch(fetchCrudList({ endpoint, filters }));
                                }}
                              />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.TRANSLATION) {
                            return (
                              <CellTranslate
                                key={i}
                                language={hc.id}
                                row={row}
                                isSelectedRow={selectedRow.id === row.id}
                                handleClick={handleClick}
                                handleSave={handleSaveRow}
                                handleChange={handleChangeTranslate}
                                isTranslateToSave={isTranslateToSave}
                              />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.WARNING) {
                            return (
                              <TableCell key={i} align="center" onClick={(e) => handleClick(e, row)}>
                                {row[hc.id] && <ErrorIcon sx={{ color: "orange" }} />}
                              </TableCell>
                            );
                          }
                          if (hc.date) {
                            return (
                              <TableCell key={i} align="left" onClick={(e) => handleClick(e, row)}>
                                {row[hc.id] && moment(row[hc.id]).utc().format("DD.MM.YYYY")}
                              </TableCell>
                            );
                          }
                          if (hc.datetime) {
                            return (
                              <TableCell
                                key={i}
                                align="left"
                                onClick={(e) => handleClick(e, row)}
                                sx={{ verticalAlign: "top", pt: 3 }}
                              >
                                {row[hc.id] && moment(row[hc.id]).utc().format("DD.MM.YYYY o HH:mm")}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              key={i}
                              align={hc.numeric ? "right" : "left"}
                              onClick={(e) => handleClick(e, row)}
                              style={!hc.bool ? { whiteSpace: "normal", wordBreak: "break-word" } : {}}
                            >
                              {!hc.bool ? (
                                row[hc.id]
                              ) : row[hc.id] ? (
                                <Checkbox size={"small"} checked disabled />
                              ) : (
                                <Checkbox size={"small"} disabled />
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </Tooltip>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {!additionalParameters?.noPagination && (
            <TablePagination
              rowsPerPageOptions={[15, 25, 50]}
              component="div"
              count={parseInt(totalItems)}
              rowsPerPage={limit}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("tp_rows_per_page")}
              labelDisplayedRows={({ from, to, count }) => {
                return "" + from + "-" + to + t("tp_from") + count;
              }}
            />
          )}
        </>
      )}
      <ConfirmationDialog
        open={deleteDialogOpen}
        close={() => dispatch(setListDeleteDialogOpen(false))}
        confirm={handleDelete}
      />
      <ConfirmationDialog
        open={cloneDialogOpen}
        close={() => dispatch(setListCloneDialogOpen(false))}
        confirm={handleClone}
        customTitle={"confirmation_modal_title_clone"}
      />
    </div>
  );
};
export default DataTable;
