import Dialog from "@mui/material/Dialog";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import DialogSelect from "components/Edit/Dialog/DialogSelect";
import * as color from "assets/styles/jss/common/colors";
import DialogTextFieldRef from "components/Edit/Dialog/DialogTextFieldRef";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";
import { MENU_ITEM_TYPES } from "enums/menuItemTypes";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useFormContext } from "react-hook-form";
import { ref } from "yup";
import { set } from "lodash";

import Box from "@mui/material/Box";
import CustomizedDropZone from "../CustomizedDropZone";
import { current } from "@reduxjs/toolkit";
import { act } from "react";

const CRAWLER_TYPES = [
  { id: "HTML_SELECTOR", displayName: "Strona statyczna" },
  { id: "DYNAMIC_SELECTOR", displayName: "Strona dynamiczna" },
  // { id: "HTML_SOURCE", displayName: "Strona statyczna - regexp" },
  { id: "DYNAMIC_SCENARIO", displayName: "Strona dynamiczna ze scenariuszem Selenium" },
];

const SELECTOR_TYPES = [
  { id: "CSS", displayName: "CSS" },
  { id: "XPATH", displayName: "XPATH" },
  { id: "REGEXP", displayName: "REGEXP" },
];

const NewPriceMonitoringItemDialog = ({ open, handleClose, handleConfirm, handleRemove, itemToEdit }) => {
  const { t } = useTranslation("common");
  const [errors, setErrors] = useState({});
  const { getValues } = useFormContext();

  const [url, setURL] = useState("");
  const refUrl = useRef(null);

  const [selectorType, setSelectorType] = useState("CSS");
  const [crawlerType, setCrawlerType] = useState("HTML_SELECTOR");

  const [selectorContent, setSelectorContent] = useState("");
  const refSelectorContent = useRef(null);

  const [loadDelay, setLoadDelay] = useState(0);
  const refLoadDelay = useRef(null);

  const [newAttachment, setNewAttachment] = useState(null);
  const [scenarioFileName, setScenarioFileName] = useState("");
  const [scenarioFileContent, setScenarioFileContent] = useState("");

  useEffect(() => {
    if (itemToEdit) {
      refUrl.current.value = itemToEdit.url;
      setURL(itemToEdit.url);
      setSelectorType(itemToEdit.selectorType);
      setCrawlerType(itemToEdit.crawlerType);

      if (refSelectorContent?.current) refSelectorContent.current.value = itemToEdit.selectorContent;
      setSelectorContent(itemToEdit.selectorContent);

      if (refLoadDelay?.current) refLoadDelay.current.value = itemToEdit.loadDelay;
      setLoadDelay(itemToEdit.loadDelay);

      setScenarioFileName(itemToEdit.scenarioFileName);
      setScenarioFileContent(itemToEdit.scenarioFileContent);
    } else {
      resetValue();
    }
  }, [itemToEdit, open]);

  const testNoEmptyValue = (name, value) => {
    if (!value || value === "") {
      setErrors((prevState) => ({
        ...prevState,
        [name]: { message: t("form-validation-required") },
      }));
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    let isError = false;
    setErrors({});

    if (!isError) {
      setErrors({});

      let item = {
        url: url,
        selectorType: selectorType,
        selectorContent: selectorContent,
        crawlerType: crawlerType,
        loadDelay: loadDelay,
        scenarioFileName: scenarioFileName,
        scenarioFileContent: scenarioFileContent,
        hasError: itemToEdit?.hasError || true,
        errorMessage: itemToEdit?.errorMessage || "No data",
        currentPrice: itemToEdit?.currentPrice || 0.0,
        sortOrder: itemToEdit?.sortOrder || 0,
        active: itemToEdit?.active || true,
      };
      if (itemToEdit?.id) {
        item.id = itemToEdit.id;
      }

      resetValue();

      handleConfirm(item);
    }
  };

  const resetValue = () => {
    setErrors({});
    if (refSelectorContent?.current) refSelectorContent.current.value = "";
    refUrl.current.value = "";
    if (refLoadDelay?.current) refLoadDelay.current.value = 0;

    setLoadDelay(0);
    setSelectorContent("");
    setURL("");
    setSelectorType("CSS");
    setCrawlerType("HTML_SELECTOR");
  };

  const handleDeleteItem = () => {
    if (itemToEdit) {
      handleRemove(itemToEdit);
    }

    resetValue();
    handleClose();
  };

  const filteredSelectorTypes =
    crawlerType === "HTML_SOURCE"
      ? SELECTOR_TYPES.filter((item) => item.id === "REGEXP")
      : SELECTOR_TYPES.filter((item) => item.id !== "REGEXP");

  useEffect(() => {
    if (crawlerType === "HTML_SOURCE") {
      setSelectorType("REGEXP");
    } else {
      setSelectorType(itemToEdit?.selectorType || "CSS");
    }
    if (refLoadDelay?.current) {
      refLoadDelay.current.value = itemToEdit?.loadDelay || 0;
    }
    if (refSelectorContent?.current) {
      refSelectorContent.current.value = itemToEdit?.selectorContent || "";
    }
  }, [crawlerType]);

  useEffect(() => {
    if (newAttachment) {
      setScenarioFileName(newAttachment.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        setScenarioFileContent(e.target.result);
      };
      reader.readAsText(newAttachment);
    } else {
      setScenarioFileName("");
    }
  }, [newAttachment]);

  const handleDownloadFile = () => {
    const element = document.createElement("a");
    const file = new Blob([scenarioFileContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = scenarioFileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={() => {
          resetValue();
          handleClose();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              Dodaj strone do śledzenia
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DialogTextFieldRef
              label={"URL produktu konkurencji"}
              name={"url"}
              errors={errors}
              setValue={setURL}
              refItem={refUrl}
            />
          </Grid>
          <Grid item xs={6}>
            <DialogSelect
              label={"Typ strony"}
              name={"crawlerType"}
              errors={errors}
              items={CRAWLER_TYPES}
              value={crawlerType}
              setValue={setCrawlerType}
            />
          </Grid>
          {crawlerType != "DYNAMIC_SCENARIO" && (
            <Grid item xs={6}>
              <DialogSelect
                label={"Typ selektora"}
                name={"selectorType"}
                errors={errors}
                items={filteredSelectorTypes}
                value={selectorType}
                setValue={setSelectorType}
              />
            </Grid>
          )}

          {crawlerType != "DYNAMIC_SCENARIO" && (
            <Grid item xs={12}>
              <DialogTextFieldRef
                label={"Selektor"}
                name={"selectorContent"}
                errors={errors}
                setValue={setSelectorContent}
                refItem={refSelectorContent}
              />
            </Grid>
          )}

          {crawlerType === "DYNAMIC_SELECTOR" && (
            <Grid item xs={6}>
              <DialogTextFieldRef
                label={"Opóźnienie ładowania strony"}
                name={"loadDelay"}
                errors={errors}
                setValue={setLoadDelay}
                refItem={refLoadDelay}
              />
            </Grid>
          )}
          {crawlerType === "DYNAMIC_SCENARIO" && (
            <Grid item xs={12}>
              <CustomizedDropZone
                label={"Scenariusz selenium"}
                selectedFile={setNewAttachment}
                placeHolder="Przeciągnij lub wybierz plik"
                accept={null}
              />
              <Box mt="2">
                {scenarioFileName && (
                  <Typography variant={"h6"} display={"flex"} mt={3}>
                    Załadowany plik: {scenarioFileName}
                    <div style={{ color: "green", cursor: "pointer", margin: "0px 5px" }} onClick={handleDownloadFile}>
                      pobierz
                    </div>
                  </Typography>
                )}
                {!scenarioFileName && (
                  <Typography variant={"h6"} mt={3}>
                    Brak załadowanego pliku
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={6}>
            <Button variant="outlined" color="default" sx={{ color: color.red }} onClick={handleDeleteItem}>
              Usuń
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Zapisz
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default NewPriceMonitoringItemDialog;
