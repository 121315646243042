import { Grid, Paper } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedDateTimePickerMuiValidLocalTimeToUtc from "components/Edit/CustomizedDateTimePickerMuiValidLocalTimeToUtc";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ABStatistic from "components/ABStatistic/ABStatistic";
import PriceMonitoringItemsPanel from "components/Edit/PriceMonitoring/PriceMonitoringItemsPanel";
import { Autocomplete, TextField } from "@mui/material";
import backend from "api/backend";
import { useFormContext } from "react-hook-form";
import { Label } from "@mui/icons-material";
import { InputLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

const endpoint = "/price-monitoring/";
const title = "marketing_price_monitoring_edit_label";
const moduleUrl = "/marketing/price-monitoring/";

const monitoringTypes = [{ id: "PRICE", displayName: "Szukaj Ceny" }];

const PriceMonitoringEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    active: Yup.bool().required(t("form-validation-required")),
    relProductId: Yup.number().required(t("form-validation-required")),
    type: Yup.string().required(t("form-validation-required")),
    items: Yup.array(
      Yup.object({
        url: Yup.string().trim().required(t("form-validation-required")),
        crawlerType: Yup.string().required(t("form-validation-required")),
        loadDelay: Yup.number().nullable(),
        selectorType: Yup.string().nullable(),
        selectorContent: Yup.string().nullable(),
        scenarioFileName: Yup.string().nullable(),
        scenarioFileContent: Yup.string().nullable(),
      }),
    ),
  });

  const defaultValue = {
    active: false,
    relProductId: null,
    type: "PRICE",
    items: [],
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        defaultValue={defaultValue}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({ id }) => {
  const [products, setProducts] = React.useState([]);

  useEffect(() => {
    async function fetchProducts() {
      const request = await backend.get("/product/getAll");
      if (request.data) {
        const prod = request.data.map((item) => {
          return { id: item.id, label: item.name };
        });
        setProducts(prod);
      }
    }
    fetchProducts();
  }, []);
  const { control, setValue, getValues, watch, formState } = useFormContext();

  const refProductIdWatch = watch("relProductId");

  const acValue =
    products.filter((item) => item.id === refProductIdWatch).length > 0
      ? products.filter((item) => item.id === refProductIdWatch)[0]
      : { id: 0, label: "Loading..." };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel>Nazwa:</InputLabel>
            <Autocomplete
              disablePortal
              name="relProductId"
              options={products}
              value={acValue}
              renderInput={(params) => <TextField {...params} label="" />}
              onChange={(event, value) => {
                setValue("relProductId", value.id);
              }}
              size="small"
            />
            {formState?.errors["relProductId"] && (
              <FormHelperText error={true}>{formState?.errors["relProductId"].message}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect
              name="type"
              label="Rodzaj monitoringu:"
              defaultValue={"PRICE"}
              items={monitoringTypes}
              noNullValue
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedSwitch name={"active"} rightLabel={"Aktywny"} topLabel={<>&nbsp;</>} />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <PriceMonitoringItemsPanel
              label={"Dodaj strony do śledzenia"}
              name={"items"}
              parentId={id}
              currentPrice={getValues("currentPrice")}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PriceMonitoringEdit;
