import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAttribute, setListPage } from "redux/slices/crud";
import { selectListFilters } from "redux/slices/crud";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const HeaderFiltrationFields = ({ itemData }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const filters = useSelector(selectListFilters);
  const [selectValue, setSelectValue] = useState("ALL");

  useEffect(() => {
    let searchValue = filters?.attributes?.find((item) => item.name === itemData.filtrationFieldName)?.value;

    if (searchValue) {
      setSelectValue(searchValue);
    } else {
      setSelectValue("ALL");
    }
  }, []);

  const handleChangeValue = (e) => {
    setSelectValue(e.target.value);
    const value = { name: itemData.filtrationFieldName, value: e.target.value === "ALL" ? "" : e.target.value };
    dispatch(setListPage(0));
    dispatch(setAttribute(value));
  };

  return (
    <Tooltip title={t(itemData.tooltip)}>
      <Select
        onChange={handleChangeValue}
        sx={{
          backgroundColor: `${alpha(color.primary, 0.02)}!important`,
          mr: 4,
          width: "240px",
          height: "38px",
          "& .MuiSelect-select": {
            backgroundColor: `${alpha(color.primary, 0.02)}!important`,
          },
        }}
        variant="outlined"
        size="small"
        value={selectValue}
      >
        {itemData?.filtrationValues?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};

export default HeaderFiltrationFields;
